var stickyHeader = true;
// var countUpStats = true;
// var countUpStatsDuration = 3000;
// var flyoutNav = true;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  /// Header
  // Initial call to handleScreenSize
  handleMenu();
  // Attach the handleScreenSize function to the window resize event
  $(window).on("resize", handleMenu);

  // Homepage ---------------
  adjustHomepageCarousel();

  // home feeds adjustment
  adjustLatestNews();
  adjustDreamStories();
  adjustDiscoverEvents();
  adjustDonate();

  // Footer ------------------
  // Move the copyright block outside
  adjustFooterSection();
  adjustNewsletter();
  adjustFooterCopyright();

  /// imapact stats function
  addimpactwrapperfld();
  /// Referrer
  newreferrer();
  /// Action
  newactsection();

  
  // Add donatepanel and banner into a new div
  var donateBanner = $(".homefeaturecategory-donatebanner .banner-wrapper");
  var donatePanel = $(".homefeaturecategory-donatepanel");
  donateBanner.wrapAll("<div class='donatebanner'></div>");
  donatePanel.wrapAll("<div class='donatepanel'></div>");
  $(".donatebanner, .donatepanel").wrapAll("<div class='donatesection'></div>");

  // Add dreamstoriestagline and feed into a new div
  var storyTagline = $(".homefeaturecategory-dreamstoriestagline .tagline-wrapper");
  var storyFeed = $(".homefeaturecategory-dreamstoriestagline .homefeaturecategory-dreamstoriesfeed");
  storyTagline.add(storyFeed).wrapAll("<div class='storysection'></div>");

  // Remove logo from footer
  const image = document.querySelector(".footerBox1 img");
  if (image) {
    image.remove();
  }

  // adding <div> to multiple <ul>s without parent control
  var wrapper = $('<div class="wrapper"></div>');
  $('section.homefeaturecategory-discovercontainer, section.homefeaturecategory-helpuscontainer').each(function() {
    var currentSection = $(this);
    currentSection.append(wrapper.clone());

    currentSection.find('ul').each(function() {
    currentSection.find('.wrapper').append(this);
    });
  });

  // Move readMore button for cards outside of <p>
  document.querySelectorAll('.feedItemDetailsWrapper').forEach(function(feedItem) {
    // Find the <p> and readMore
    var para = feedItem.querySelector('p');
    var readMore = feedItem.querySelector('.readMore');

    if (para && readMore) {
      feedItem.appendChild(readMore);
    }
  });

  // Referrer network add everything into a single div
  var referrernet = $(".homefeaturecategory-referrercontainer");

// move internal news outside of block
  // Select elements
  const contentBlockWrapper = document.querySelector(".contentBlockWrapper");
  const contentBlock = document.querySelector(".contentBlock");
  const postAside = document.querySelector(".postAside");

  if (contentBlockWrapper && contentBlock && postAside) {
      // Move aside outside contentBlockWrapper
      contentBlockWrapper.parentNode.insertBefore(postAside, contentBlockWrapper.nextSibling);
  }

// Move div and ul into another div for Starter
//$(".homefeaturecategory-startercontainer").append($("<div class='starter-container'></div>").append($(".Placeholder, ul")));
const section = document.querySelector(".homefeaturecategory-startercontainer");
const container = document.createElement("div");
container.classList.add("starter-container");

container.append(...section.querySelectorAll(".Placeholder, ul"));
section.appendChild(container);

});

function adjustHomepageCarousel() {
  // reference elements
  const container = $("body.homepage #carouselSlides");
  // modify first slide button text
  container.find("li:first-of-type .carouselSlideReadMore").html("Donate now");
}

function adjustNewsletter() {
  // Create a new main section
  var $mainSection = $('<div class="footerBox mainNewsletter"></div>');
  // Append footerBox1 and footerBox2 into the new main section
  $(".NewsletterTagline, .NewsletterSign-up").appendTo($mainSection);
  // Append the main section to the pageFooterWrapper
  $mainSection.prependTo(".pageFooter");
}

function adjustFooterSection() {
  // Create a new main section
  var $footerSection = $('<div class="footerBox mainFooterSection"></div>');
  // Append footerBox3, footerBox4, footerBox5 and footerBox6 into the new main section
  $(
    ".FooterQuicklinks, .FooterGetintouch, .FooterSocials, .FooterLogos"
  ).appendTo($footerSection);
  // Append the main section to the pageFooterWrapper
  $footerSection.prependTo(".pageFooter");
}

function adjustFooterCopyright() {
  // Reference the footer parent
  const footerParent = $("#pageFooterWrapper");
  // Reference the copyright block
  const footerCopyright = $("#pageFooterWrapper .FooterCredits");
  // Move it outside the default wrapper
  footerCopyright.appendTo(footerParent);
}

function adjustDiscoverEvents() {
  // initiate feed
  initCarousel(
    "homeFeedBox1",
    "Explore all events",
    "/Listing/Category/discover-our-next-events",
    "Slide for more Events",
    false,
    3
  );
  // element ref
  const news = $(".homefeaturecategory-oureventsfeed");
  const feedbox = $(".homeFeedBox1");
  // put in the divs
  news.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  feedbox.appendTo(news);
}

function adjustLatestNews() {
  initCarousel(
    "homeFeedBox3",
    "Latest news",
    "/Listing/Category/latest-news",
    "See all news",
    false,
    3
  );
  // element ref
  const news = $(".homefeaturecategory-latestnewsfeed");
  const feedbox = $(".homeFeedBox3");
  // put in the divs
  news.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  feedbox.appendTo(news);
}

function adjustDreamStories() {
  // initiate feed
  initCarousel(
    "homeFeedBox2",
    "See all Dream stories",
    "/Listing/Category/dream-stories",
    "Slide for more Dream stories",
    false,
    2
  );
  // element ref
  const tagline = $(".homefeaturecategory-dreamstoriestagline");
  const stories = $(".homefeaturecategory-dreamstoriesfeed");
  const feedbox = $(".homeFeedBox2");
  // put all the divs
  tagline.wrapInner('<div class="tagline-wrapper"></div>');
  stories.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  feedbox.appendTo(stories);
  stories.appendTo(tagline);
}

// Carousel functions
function initCarousel(
  feed,
  ctaText,
  ctaURL,
  slideText,
  optFade = false,
  optShow = 2,
  optAuto = false,
  optHeight = false
) {
  // Set the carousel selector
  let carousel = ".homeFeed." + feed;
  // Check screen size to adjust number of slides to show
  if (window.innerWidth < 1024) {
    optShow = 2;
  }
  // If carousl exists on page
  if ($(`${carousel}`).length) {
    // Adjust the feed details first
    adjustFeedDetails(carousel);
    // Create the slider arrows + dots + cta
    let elements = createSliderElements(ctaText, ctaURL, slideText);
    // Then append under the carousel
    $(`${carousel}`).append(elements.navigator);
    // Init slick slider
    $(`${carousel} .feedList`).slick({
      dots: true,
      arrows: true,
      appendDots: $(`${carousel} .dot-wrapper`),
      prevArrow: $(`${carousel} .prev-slide`),
      nextArrow: $(`${carousel} .next-slide`),
      fade: optFade,
      infinite: true,
      slidesToShow: optShow,
      slidesToScroll: 1,
      autoplay: optAuto,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
}

function adjustFeedDetails(carousel) {
  // for each feed item present
  $(`${carousel} .feedItem`).each(function () {
    // Set reference to both footer and details wrapper of feed
    let footerWrapper = $(this).find(".postFooterWrapper");
    let detailsWrapper = $(this).find(".feedItemDetailsWrapper");
    // Move the footer wrapper to the details wrapper
    footerWrapper.prependTo(detailsWrapper);
  });
}

function createSliderElements(ctaText, ctaURL, scrollText) {
  let iconPrev = '<i class="fa fa-chevron-left" aria-hidden="true"></i>';
  let iconNext = '<i class="fa fa-chevron-right" aria-hidden="true"></i>';
  let prevArrow =
    '<button type="button" aria-label="Previous slide" class="prev-slide">' +
    iconPrev +
    "</button>";
  let nextArrow =
    '<button type="button" aria-label="Next slide" class="next-slide">' +
    iconNext +
    "</button>";
  let dotCustom = '<div class="dot-wrapper"></div>';
  let sliderNav =
    '<div class="slider-nav">' + prevArrow + dotCustom + nextArrow + "</div>";
  let slideMore = '<p class="slider-label">' + scrollText + "</p>";
  let sliderBox = '<div class="slider-box">' + slideMore + sliderNav + "</div>";
  let ctaButton =
    '<a class="button" href="' +
    ctaURL +
    '" aria-label="' +
    ctaText +
    '">' +
    ctaText +
    "</a>";
  let navigator = '<div class="navigator">' + sliderBox + ctaButton + "</div>";
  return {
    prevArrow,
    nextArrow,
    dotCustom,
    sliderNav,
    slideMore,
    sliderBox,
    navigator,
  };
}

function adjustDonate() {
  // element ref
  const banner = $(".homefeaturecategory-donatebanner");
  const panel = $(".homefeaturecategory-donatepanel");
  // put all the divs
  banner.wrapInner('<div class="banner-wrapper"></div>');
  panel.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  panel.appendTo(banner);
}

//Create impact wrapper div adding everything except image
function addimpactwrapperfld() {
  var homeImpactWrapper = $(".homefeaturecategory-homeboximpactstats .homeImpactWrapper");
  var mediaImage = homeImpactWrapper.find(".mediaImage");
  var impactWrapper = $("<div class='impact-wrapper'></div>");
  //Move all elements inside except the image
  homeImpactWrapper.children().not(mediaImage).appendTo(impactWrapper);
  homeImpactWrapper.append(impactWrapper);
};

// Move all elements from referrer to another div inside of it
function newreferrer() {
  $(".homefeaturecategory-referrercontainer").each(function () {
    var wrapper = $("<div class='referrer-wrapper'></div>");
    $(this).children("img, ul").appendTo(wrapper);
    $(this).append(wrapper);
  });
};

// Move all elements from action section to another div inside of it
function newactsection() {
  $(".homefeaturecategory-actioncontainer").each(function () {
    var wrapper = $("<div class='action-wrapper'></div>");
    $(this).children("img, ul").appendTo(wrapper);
    $(this).append(wrapper);
  });
};

// Move menu between logo and main CTA
function handleMenu() {
  // move menu to between logo and main CTA
  if ($(window).width() > 1329) {
    // Check if the menu's parent is the header content or not
    if (!$("#menuMain").parent().is($("#pageHeader .headerContent"))) {
      // Else append the menu to the header content
      $("#menuMain").appendTo("#pageHeader .headerContent");
    }
  } else {
    // Check if the menu is inserted after or not
    if (!$("#pageHeader .headerContent").next().is($("#menuMain"))) {
      // Else insert the new element after the target element
      $("#menuMain").insertAfter("#pageHeader .headerContent");
    }
  }
}